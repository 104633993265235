<template>
  <div>
    <div hidden>
      <h4>Доступно для отправки на объект хранения</h4>
      <p>Максимальное значение: 0.0 шт. (штук)</p>
      <p>Текущее значение: 15.0 шт. (штук)</p>
      <h4 style="color: red">Заполненность: 100 %</h4>
    </div>

    <el-form
      :model="inStorageForm"
      :rules="rules"
      ref="inStorageForm"
      label-position="top"
      label-width="120px"
    >


    <el-row>
      <el-col :span="6">
          <el-form-item prop="date" label="Дата">
            <el-date-picker value-format="yyyy-MM-dd" v-model="inStorageForm.date" type="date"
            @change="setDatastored">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item  prop="ammount" label="" v-if="datastored !== null">
            Доступно на {{inStorageForm.date}} для проведения операции: {{ datastored.amount-datastored.sumAmountGet }} {{ datastored.pss93UnitInfo.name }}
          </el-form-item>
        </el-col>
    </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-row>
          <el-form-item prop="store" label="Количество">
            <el-input v-model="inStorageForm.store" ></el-input>
          </el-form-item>
          </el-row>
          
        </el-col>
        <el-col :span="6">
          <el-form-item label="Единица измерения">
            <el-input v-model="waste.pss93UnitInfo.name" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
            <p style="color: red" v-if="datastored !== null && parseFloat(inStorageForm.store.toString().replace(',', '.')) > valueDatastor">Ошибка! Превышение доступного количества отхода</p>
          </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="pss22Info"
            label="Наименование объекта, места хранения отходов"
          >
            <el-select
              placeholder="Наименование объекта"
              class="searchPole"
              value-key="id"
              v-model="inStorageForm.pss22Info"
              filterable
            >
              <el-option
                v-for="item in pss22StoredObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <!--      <el-row>-->
      <!--        <el-col :span="24">-->
      <!--          <el-form-item-->
      <!--            prop="organizationInfo"-->
      <!--            label="Филиал / Подразделение / Участок:"-->
      <!--          >-->
      <!--            <el-select-->
      <!--              style="width: 100%"-->
      <!--              clearable-->
      <!--              value-key="id"-->
      <!--              v-model="inStorageForm.organizationInfo"-->
      <!--              filterable-->
      <!--              placeholder="Выберите"-->
      <!--            >-->
      <!--              <el-option-->
      <!--                v-for="item in organizationHierarchyByType"-->
      <!--                :key="item.id"-->
      <!--                :label="item.name"-->
      <!--                :value="item"-->
      <!--              >-->
      <!--              </el-option>-->
      <!--            </el-select>-->
      <!--          </el-form-item>-->
      <!--        </el-col>-->
      <!--      </el-row>-->

      <el-row>
        <el-col :span="24">
          <el-form-item
            prop="organizationInfoSentForStorageTo"
            label="Филиал / Подразделение / Участок "
          >
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="inStorageForm.organizationInfoSentForStorageTo"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in sentForStorageOrganizationList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="inStorageForm.organizationInfoSentForStorageTo.id !== null">
        <el-col :span="24">
          <el-form-item prop="selectedTechProcess" label="Тех. процесс">
            <el-select
              style="width: 100%"
              clearable
              value-key="id"
              v-model="selectedTechProcess"
              filterable
              placeholder="Выберите"
            >
              <el-option
                v-for="item in inStorageForm.organizationInfoSentForStorageTo
                  .objectList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-button @click="save" type="primary" style="float: right" :disabled="parseFloat(inStorageForm.store.toString().replace(',', '.')) > valueDatastor||block ||(new Date(inStorageForm.date.split('-')[0],inStorageForm.date.split('-')[1]-1,inStorageForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1)  && (userId!=409   && userId!=265 && userId!=266 && userId!=267
&& userId!=268 && userId!=269 && userId!=270 && userId!=271
&& userId!=272 && userId!=273 && userId!=274 && userId!=275
&& userId!=276 && userId!=277 && userId!=278 && userId!=279
&& userId!=280 && userId!=281 && userId!=282 && userId!=283
&& userId!=284 && userId!=285 && userId!=286 && userId!=287
&& userId!=288 && userId!=289 && userId!=290  && userId!=233 && userId!=234 && userId!=25 && userId!=292 && userId!=245 && userId!=14 && userId!=465 && userId!=505 && userId!=506 && userId!=507 && userId!=428 && userId!=124))"
            >Сохранить</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";
import Cookies from "js-cookie";
export default {
  name: "inStorageModal",
  props: ["waste"],
  mixins: [notification],
  data() {
    return {
      userId:Cookies.get('userId') ,
      block: false,
      dateDatastore: null,
      valueDatastor:0,
      datastored:null,
      pss22StoredObjectInfoList: null,
      selectedTechProcess: null,
      actionId: 8,
      inStorageForm: {
        amount: this.waste.amount,
        store: "0",
        date: "",
        organizationInfoSentForStorageTo: {
          id: null,
          name: null,
          objectList: null,
        },
        pss22Info: null,
        // organizationInfo: null,
      },

      rules: {
        store: [
          { required: true, message: "Введите значение", trigger: "blur" },
        ],

        date: [
          { required: true, message: "Введите значение", trigger: "blur" },
        ],

        pss22Info: [
          { required: true, message: "Введите значение", trigger: "blur" },
        ],

        

        organizationInfoSentForStorageTo: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        organizationInfo: [
          { required: true, message: "Введите количество", trigger: "blur" },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      sentForStorageOrganizationList: "GETSENTFORSTORAGEORGANIZATIONLIST",
    }),
  },

  methods: {
    setDatastored(){
      if((new Date(this.inStorageForm.date.split('-')[0],this.inStorageForm.date.split('-')[1]-1,this.inStorageForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=25 && this.userId!=292 && this.userId!=245 && this.userId!=14 && this.userId!=465 && this.userId!=505 && this.userId!=506 && this.userId!=507 && this.userId!=428 && this.userId!=124)))
        this.message("Внимание! Внесение данных за "+new Date(this.validat().item.date).getFullYear()+" год ограничено. Закрыт отчетный период", "error", true);
      this.getDatastored().then((response) => {
        if(response.data == true)
        {this.block=true;
          this.message("Внимание! Цепочка устарела!", "error", true);
          return;
        }
        if(response.data == false)
        {this.block=true;
          this.message("Внимание! Цепочка не начала действовать!", "error", true);
          return;
        }
        this.block=false;
          this.datastored = response.data;
      this.valueDatastor = this.datastored.amount-this.datastored.sumAmountGet;
      this.dateDatastore = Date.parse(response.data.path);
      if(this.dateDatastore > Date.parse(this.inStorageForm.date,'yyyy-MM-dd'))
      this.message("Внимание! Внесение информации на данной вкладке приведет к перерасчету количества отходов и изменениям в отчетной документации. Будьте внимательны при заполнении!", "error", true);
    });
    },

    async getDatastored(){
    let data = {date:"",techProcessHasWasteId:""};
    data.date=this.inStorageForm.date;
    data.techProcessHasWasteId = this.waste.techProcessHasWasteId;
    return await this.$store.dispatch(
                "getDataStored",
                data
              );
  },

  validat(){
      let i = 0;
        if(this.inStorageForm.date == "" || this.inStorageForm.store== "")
        //{this.ErrorMasseg("Внимание! Заполните всте поля");
          i= 1;
        if((new Date(this.inStorageForm.date.split('-')[0],this.inStorageForm.date.split('-')[1]-1,this.inStorageForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=25  && this.userId!=292 && this.userId!=245 && this.userId!=14 && this.userId!=465 && this.userId!=505 && this.userId!=506 && this.userId!=507 && this.userId!=428 && this.userId!=124)))
        //{this.ErrorMasseg("Внимание! Дата одного из значений меньше чем "+new Date().getFullYear()+" год");
          i= 2;
      return {id:i,item:this.inStorageForm};
    },
    save() {
      switch (this.validat().id)
      {
        case 1: 
        this.ErrorMasseg("Внимание! Заполните все поля");
        return;
        case 2: 
        this.ErrorMasseg("Внимание! Внесение данных за "+new Date(this.validat().item.date).getFullYear()+" год ограничено. Закрыт отчетный период")
        return;
        case 0: break;
      }
      this.$refs["inStorageForm"].validate((valid) => {
        if (valid) {
          this.inStorageForm.amount = this.waste.amount;
          console.log("VALID", this.inStorageForm);
          this.inStorageForm.transferredFromUnitId = this.$route.params.unitId;
          
          this.$store
            .dispatch("receiveDataEntryForm", {
              modalData: this.inStorageForm,
              id: this.$route.params.processId,
              actionId: this.actionId,
            })
            .then(() => {
              this.$store.dispatch(
                "getMainPageForDataEntry",
                this.$route.params.processId
              );
              this.notification("Успешно", "Данные внесены", "success");
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("setShowModal", false);
            });
        } else {
          return false;
        }
      });
    },

    getPss22StoredObjectInfoList() {
      this.getSubClassifierById(22).then((response) => {
        this.pss22StoredObjectInfoList = response.data.content;
      });
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },
  },

  mounted() {
    this.$store.dispatch("getOrganizationHierarchyByType", { type: 3 });
    this.$store.dispatch("getSentForStorageOrganizationList", {
      organizationWasteId: this.$route.params.wasteId,
      unitId: this.$route.params.unitId,
    });
    this.getPss22StoredObjectInfoList();
  },
};
</script>

<style scoped></style>
